import { Header as PlasticHeader } from '@cof/plastic-components';
import PropType from 'prop-types';

import styled, { css } from 'styled-components';
import LogoComponent from './LogoComponent';
import { useJourneyContext } from '../../data/JourneyContext';

const hasAggregatorLogo = (consumerName) =>
    ['MSM', 'CLRS', 'TOTM', 'CTM', 'USWC', 'MCU', 'EXPR', 'CK'].includes(consumerName);

export const StyledHeader = styled(PlasticHeader)`
    ${({
        theme: {
            components: { header }
        },
        showAggregatorLogo
    }) => css`
        background-color: ${showAggregatorLogo
            ? header.colors.aggregators.background
            : header.colors.background.desktop};
        border: ${showAggregatorLogo ? header.colors.aggregators.border : header.colors.border.desktop};
    `};
`;

const Header = () => {
    const { aggregatorConsumerName } = useJourneyContext();
    const showAggregatorLogo = hasAggregatorLogo(aggregatorConsumerName);

    return (
        <StyledHeader showAggregatorLogo={showAggregatorLogo}>
            <LogoComponent showAggregatorLogo={showAggregatorLogo} />
        </StyledHeader>
    );
};

Header.propTypes = {
    appForm: PropType.bool
};

export default Header;
